body {
  margin: 0;
  font-size: 16px;
  font-family: 'Work Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*,
:before,
:after {
  box-sizing: border-box;
}

*:focus,
*:focus-within,
*:active {
  outline: none !important;
}

/* reach-ui */
[data-reach-dialog-overlay] {
  z-index: 1000;
}
